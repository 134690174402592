import { render, staticRenderFns } from "./JuliaFractal.vue?vue&type=template&id=789c3463&scoped=true&"
import script from "./JuliaFractal.vue?vue&type=script&lang=js&"
export * from "./JuliaFractal.vue?vue&type=script&lang=js&"
import style0 from "./JuliaFractal.vue?vue&type=style&index=0&id=789c3463&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "789c3463",
  null
  
)

export default component.exports