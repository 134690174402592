<template>
  <div id="app">
    <JuliaFractal/>
  </div>
</template>

<script>
import JuliaFractal from './components/JuliaFractal.vue'

export default {
  name: 'app',
  components: {
    JuliaFractal
  }
}
</script>

<style>
