<template>
	<div class="hello">
		<canvas id="canvas"></canvas>
	</div>
</template>

<script>

//import Complex from '../resources/Complex.js'
import complexArrIterator from '../resources/complexArrIterator.js'
//import complexDeltaArr from '../resources/complexDeltaArr.js'

const vertexShader = `
		attribute vec2 a_position;

		void main() {
			gl_Position = vec4(a_position, 0, 1);
		}
`;

const fragmentShader = `
#ifdef GL_ES
precision mediump float;
#endif

uniform vec2 seed;
uniform vec2 resolution;



void main( void ) {
	vec2 coords = ((gl_FragCoord.xy / resolution) - vec2(0.5, 0.5)) * 4.0 * vec2(1.0, resolution.y / resolution.x);
	const int iterations = 1024;
	const float excess = 1.0;
	const vec3 baseColor = vec3(255.0, 11.0, 250.0) * excess / 256.0;
	// const vec3 baseColor = vec3(0.0, 64.0, 255.0) * excess / 256.0;
	// const vec3 backgroundColor = vec3(0.0, 0.0, 0.0);
	// float coeffIter = float(iterations);
	vec2 point = coords;
	float x2, y2;
	int i1;
	for (int i = 0; i < iterations; i++) {
		i1 = i;
		x2 = point.x * point.x;
		y2 = point.y * point.y;
		if (x2 + y2 > 4.0) {
			break;
		}
		point = vec2(x2 - y2, 2.0 * point.x * point.y) + seed;
	}
	float fi = float(i1) / float(iterations);
    // vec3 color = baseColor * (fi/coeffIter) + backgroundColor * (float(iterations - i)/coeffIter);
    // vec3 color = baseColor * (fi/coeffIter);
	// float red = min(1.0, fi * 3.0);
	// float green = max(0.0, min(1.0, fi * 3.0 - 1.0));
	// float blue = max(0.0, min(1.0, fi * 3.0 - 2.0));
	const float tempCoeff = 2.5;
	const float redPoint = 0.0;
	const float greenPoint = 2.0;
	const float bluePoint = 2.5;
	float temperature = fi * tempCoeff;
    gl_FragColor = vec4(temperature - redPoint, temperature - greenPoint, temperature - bluePoint, 1.0);
}
`;

export default {
	name: 'Fractal',
	data() {
		return { a: 1 };
	},
	mounted() {                // Get A WebGL context
		var canvas = document.getElementById("canvas");
		var gl;
		var program;

		// draw
		window.addEventListener('resize', resizeCanvas, false);
		window.addEventListener('wheel', event => {
			const delta = Math.sign(event.deltaY);
			window.mSpeed = Math.max(0, window.mSpeed + delta);
			return false;
		}, false);

		/////////
		function resizeCanvas() {
			const scale = window.devicePixelRatio;
			const width = window.innerWidth
			const height = window.innerHeight
			canvas.width = width * scale;
			canvas.height = height * scale;

			canvas.style.width = width + 'px';
			canvas.style.height = height + 'px';

			gl = canvas.getContext("webgl");

			// setup a GLSL program
			program = window.createProgramFromSources(gl, [vertexShader, fragmentShader]);
			gl.useProgram(program);

			// look up where the vertex data needs to go.
			var positionLocation = gl.getAttribLocation(program, "a_position");

			// Create a buffer and put a single clipspace rectangle in
			// it (2 triangles)
			var buffer = gl.createBuffer();
			gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
			gl.bufferData(
				gl.ARRAY_BUFFER,
				new Float32Array([
					-1.0, -1.0,
					1.0, -1.0,
					-1.0, 1.0,
					-1.0, 1.0,
					1.0, -1.0,
					1.0, 1.0]),
				gl.STATIC_DRAW);
			gl.enableVertexAttribArray(positionLocation);
			gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);
			////////////////////////////////////////////////////////////////////////////
			var resolution = gl.getUniformLocation(program, "resolution");
			gl.uniform2f(resolution, canvas.width, canvas.height);
		}
		resizeCanvas();
		////////
		/* eslint-disable-next-line no-console */
		//console.log(window.complexDeltaArr);
		var iterator = complexArrIterator(...window.complexDeltaArr);
		const stepTime = 20;
		let stepNumber = Math.floor(Date.now() / stepTime);
		window.mSpeed = 1;
		setInterval(function () {
			const currentStepNumber = Math.floor(Date.now() / stepTime);
			const delta = (currentStepNumber - stepNumber) * window.mSpeed;
			stepNumber = currentStepNumber;
			if (delta === 0) {
				return;
			}
			const r = iterator.next(delta);
			if (!r.done) {
				const value = r.value;
				var seed = gl.getUniformLocation(program, "seed");
				gl.uniform2f(seed, value.re, value.im);

				gl.drawArrays(gl.TRIANGLES, 0, 6);


				//                        drawPixel(value.re, value.im, 255, 0, 0, 255);
				//						updateCanvas();
			}
		}, stepTime);

		// `this` указывает на экземпляр vm
		//		console.log('Значение a: ' + this.a)
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
canvas {
	display: block;
}
</style>
