'use strict';

export default class Complex {
    constructor(re, im) {
        this.re = re;
        this.im = im;
    }
    add(c) {
        this.re += c.re;
        this.im += c.im;
        return this;
    }
    decrease(c) {
        this.re -= c.re;
        this.im -= c.im;
        return this;
    }
    sub(c) {
        return this.clone().decrease(c);
    }
    sum(c) {
        return this.clone().add(c);
    }
    mul(c) {
        [this.re, this.im] = [this.re * c.re - this.im * c.im, this.re * c.im + this.im * c.re];
        return this;
    }
    mulReal(n) {
        this.re *= n;
        this.im *= n;
        return this;
    }
    rotateRightNormal() {
        [this.re, this.im] = [this.im, -this.re];
        return this;
    }
    rotateLeftNormal() {
        [this.re, this.im] = [-this.im, this.re];
        return this;
    }
    clone() {
        return new Complex(this.re, this.im);
    }
    equals(c) {
        return this.re == c.re && this.im == c.im;
    }
}