'use strict';

import Complex from './Complex.js'

export default function* (arr, firstParams, precision) {
    const delta = Math.pow(0.5, precision);
    const c = new Complex(firstParams[0]*delta, firstParams[1]*delta);
	let i = 0;
	let direction = 1;
	while (true) {
		const steps = yield c.clone();
		for (let j = 0; j < steps; j++) {
			const [bigRe, bigIm] = arr[i];
			c.add(new Complex(bigRe*delta*direction, bigIm*delta));
			i += direction;
			if (i === arr.length || i < 0) {
				direction = -direction;
				c.im = -c.im;
				i += direction;
			}
		}
	}
}